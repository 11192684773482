import { Box, Stack } from '@chakra-ui/layout';
import { getColor } from '@utils/getColor';
import dynamic from 'next/dynamic';
import { DamChooserImage, ExternalSourceImage } from '~/types/Magnolia';
const FullWidthContainer = dynamic(
  () => import('@components/LayoutStructure/FullWidthContainer')
);

import HeroImage from './HeroImage';
import SiteContext from '~/contexts/SiteContext';
import { useContext } from 'react';
import { HeroInquiryForm, HeroInquiryFormProps } from './HeroInquiryForm';
import Container from '@components/LayoutStructure/Container';
interface HeroProps extends HeroInquiryFormProps {
  backgroundColor?: string;
  backgroundColorRange?: string;
  fullBleedImage?: boolean;
  damChooser?: DamChooserImage;
  externalSource?: ExternalSourceImage;
}

const Hero = (props: HeroProps): JSX.Element => {
  const {
    backgroundColor,
    backgroundColorRange,
    damChooser,
    externalSource,
    fullBleedImage = false,
    ...inquiryProps
  } = props;
  const siteContext = useContext(SiteContext);
  const domain = siteContext.site?.path || '';

  if (domain === 'caring.com' && fullBleedImage) {
    return (
      <FullWidthContainer
        bg={getColor(backgroundColor, backgroundColorRange)}
        px={0}
        py={0}
      >
        <Box
          data-testid="full-bleed-image-container"
          position="relative"
          margin="0 auto"
        >
          <HeroImage
            externalSource={externalSource}
            damChooser={damChooser}
            hideClipPath
            imageHeight={{ base: '1012px', md: '936px', lg: '850px' }}
          />
          <Container
            position="absolute"
            margin="auto"
            left="50%"
            top={{ base: '85%', lg: '50%' }}
            transform={{
              base: 'translate(-50%, -85%)',
              lg: 'translate(-50%, -50%)',
            }}
          >
            <HeroInquiryForm {...inquiryProps} />
          </Container>
        </Box>
      </FullWidthContainer>
    );
  }

  return (
    <FullWidthContainer
      bg={getColor(backgroundColor, backgroundColorRange)}
      px={0}
      py={0}
    >
      <Stack direction={{ base: 'column', lg: 'row' }}>
        <Box
          p={8}
          width={{ base: '100%', md: '70%' }}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <HeroInquiryForm {...inquiryProps} />
        </Box>
        <HeroImage externalSource={externalSource} damChooser={damChooser} />
      </Stack>
    </FullWidthContainer>
  );
};

export default Hero;
