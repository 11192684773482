import { Box, BoxProps } from '@chakra-ui/layout';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useEffect, useMemo, useState } from 'react';
import { IMAGE_DOMAINS } from '~/constants';
const DAMImage = dynamic(() => import('@components/Image/DAMImage'));

import {
  DamChooserImage,
  ExternalImage,
  ExternalSourceImage,
  MagnoliaImage,
} from '~/types/Magnolia';

export interface HeroImageProps {
  externalSource?: ExternalSourceImage;
  damChooser?: DamChooserImage;
  hideClipPath?: boolean;
  imageHeight?: BoxProps['height'];
}

const HeroImage = (props: HeroImageProps): JSX.Element => {
  const {
    externalSource,
    damChooser,
    hideClipPath = false,
    imageHeight = 'xl',
  } = props;

  const [activeSlide, setActiveSlide] = useState(0);

  const images = useMemo(() => {
    const images: (MagnoliaImage | ExternalImage)[] = [];
    if (externalSource) {
      externalSource['@nodes'].forEach((node) => {
        const img = externalSource[node] as ExternalImage;
        if (!IMAGE_DOMAINS.some((url) => img.imageUrl.includes(url))) {
          console.error('Invalid host in image url');
          return {};
        }
        images.push(img);
      });
    }
    if (damChooser) {
      damChooser['@nodes'].map((node) => {
        const img = damChooser[node] as { image: MagnoliaImage };
        if (img?.image) {
          images.unshift(img.image);
        }
      });
    }
    return images;
  }, [damChooser, externalSource]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((curr) => (curr + 1 < images?.length ? curr + 1 : 0));
    }, 3000);
    return () => clearInterval(interval);
  }, [images]);

  if (!images?.length) {
    return <></>;
  }

  return (
    <Box
      data-testid="hero-image-box"
      position="relative"
      width="100%"
      height={imageHeight}
      clipPath={
        hideClipPath
          ? undefined
          : {
              base: '',
              lg: 'polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%)',
            }
      }
    >
      {images &&
        images.map((image, index) => {
          return (
            <motion.div
              key={index}
              animate={{ opacity: activeSlide === index ? 1 : 0 }}
              transition={{ duration: 1 }}
              initial={false}
            >
              {image['metadata'] ? (
                <DAMImage
                  src={image as MagnoliaImage}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    position: 'absolute',
                    objectPosition: 'center',
                  }}
                />
              ) : (
                <></>
              )}
              {image['imageUrl'] ? (
                <Image
                  alt={image['imageAlt']}
                  src={image['imageUrl']}
                  fill
                  style={{ objectFit: 'cover' }}
                />
              ) : (
                <></>
              )}
            </motion.div>
          );
        })}
    </Box>
  );
};

export default HeroImage;
