import { Button } from '@chakra-ui/button';
import { Flex, Heading, Link, Text, VStack } from '@chakra-ui/layout';
import MiniSelfSearch from '@components/MiniSelfSearch';
import { useBreakpointValue } from '@chakra-ui/media-query';
import {
  StringToIconKeys,
  STRING_TO_ICON_COMPONENT,
} from '@components/RenderIcon';
import { Section } from '@components/Sections';
import { getButtonColor, getColor } from '@utils/getColor';
import { HeadingElements } from '~/@types/heading';
import { Metadata } from '~/types/Magnolia';

import {
  CallToActionProps,
  DEFAULT_CTA_BEHAVIOR,
} from '../NewCallToAction/NewCallToAction';
import { Divider } from '@chakra-ui/react';

export interface HeroInquiryFormProps {
  headingElement: HeadingElements;
  tileBackground?: boolean;
  tileBackgroundOpacity?: number;
  title: string;
  titleColor?: string;
  titleColorRange?: string;
  text?: string;
  textColor?: string;
  textColorRange?: string;
  search: {
    field: 'enableSearch' | 'disableSearch';
    heroSearchId: string;
    searchTitle?: string;
    searchTitleColor?: string;
    inputAlignment?:
      | 'left'
      | 'center'
      | 'right'
      | 'flex-start'
      | 'flex-end'
      | 'start'
      | 'end';
    showDivider?: boolean;
    buttonOutsideInput?: boolean;
    enablePredictiveSearch?: boolean;
    searchButton?: {
      text?: string;
      mobileText?: string;
      buttonTextColor?: string;
      buttonBgColor?: string;
      state?: 'solid' | 'outline' | 'ghost';
      behavior?: '_blank' | '_self' | '_parent' | '_top';
    };
  };
  secondaryCta: {
    field: 'enableSecondaryCta' | 'disableSecondaryCta';
    ctaType: {
      field: string;
      button?: CallToActionProps;
      text: string;
      textColor: string;
      textColorRange: string;
      secondText?: string;
      secondTextColor?: string;
      secondTextColorRange?: string;
      thirdText?: string;
      thirdTextColor?: string;
      thirdTextColorRange?: string;
      url?: string;
      behavior?: '_blank' | '_self' | '_parent' | '_top';
      rel?: ['external' | 'nofollow' | 'noopener' | 'noreferrer' | 'opener'];
      type?: 'tel' | 'mailto' | 'sms';
    };
  };
  metadata: Metadata;
}

export const HeroInquiryForm = (props: HeroInquiryFormProps) => {
  const {
    tileBackground = false,
    tileBackgroundOpacity = 20,
    headingElement,
    title,
    text,
    search,
    secondaryCta,
    textColor,
    textColorRange,
    titleColor,
    titleColorRange,
  } = props;
  const hasCta = secondaryCta?.field === 'enableSecondaryCta';
  const ctaType = hasCta && secondaryCta?.ctaType?.field;
  const cta =
    ctaType === 'button'
      ? secondaryCta?.ctaType?.button
      : secondaryCta?.ctaType;
  const ctaButton = secondaryCta?.ctaType?.button;
  const ctaLink = secondaryCta?.ctaType;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isMobile = useBreakpointValue({ base: true, md: false });

  const searchText = isMobile
    ? search.searchButton?.mobileText || search.searchButton?.text
    : search.searchButton?.text;

  const getIcon = (iconName?: StringToIconKeys) => {
    if (!iconName) return '';
    return { leftIcon: STRING_TO_ICON_COMPONENT[iconName] };
  };

  const tileProps = tileBackground
    ? {
        backgroundColor: `rgba(255, 255, 255, ${tileBackgroundOpacity / 100})`,
        borderRadius: 'md',
        py: 10,
        px: { base: 5, lg: 10 },
      }
    : {};

  return (
    <VStack
      data-testid="hero-form-container"
      alignItems="flex-start"
      spacing={{ base: '1', lg: '6' }}
      width={{ base: '100%', lg: '601px' }}
      {...tileProps}
      lineHeight={{ base: '24px', lg: '30px' }}
    >
      <Section
        headingElement={headingElement}
        headingSize="xl"
        title={title}
        titleColor={titleColor}
        titleColorRange={titleColorRange}
        richText={text}
        richTextColor={textColor}
        richTextColorRange={textColorRange}
        richTextSize="xl"
      />
      <VStack alignItems="flex-start" width="100%">
        {search?.field === 'enableSearch' && (
          <>
            {search.showDivider && (
              <Divider
                data-testid="search-bar-divider"
                borderColor={'#C2C2C2'}
                my={{ base: 0, md: 4 }}
              />
            )}
            {search.searchTitle && (
              <Text
                fontSize={{ base: 'md', lg: 'xl' }}
                fontWeight="bold"
                pt={{ base: 2, lg: 4 }}
              >
                {search.searchTitle}
              </Text>
            )}
            <MiniSelfSearch
              text={searchText}
              textColor={getColor(search.searchButton?.buttonTextColor)}
              bgColor={search.searchButton?.buttonBgColor}
              fullWidth
              miniSelfSearchId={props.metadata['@id']}
              inputAlignment={search?.inputAlignment}
              buttonOutsideInput={search.buttonOutsideInput}
              enablePredictiveSearch={search.enablePredictiveSearch}
            />
          </>
        )}
        {cta && (
          <Link
            href={`${cta.type ? `${cta.type}:` : ''}${cta.url}`}
            key={cta.text}
            target={cta.behavior || DEFAULT_CTA_BEHAVIOR}
            rel={cta?.rel?.join(' ') || ''}
            color={getColor(ctaLink?.textColor, ctaLink?.textColorRange)}
            textDecoration="none"
          >
            {ctaType === 'button' ? (
              <Button
                colorScheme={ctaButton?.bgColor}
                variant={ctaButton?.state}
                {...getButtonColor(ctaButton?.textColor)}
                {...getIcon(ctaButton?.icon)}
              >
                {cta.text}
              </Button>
            ) : (
              <Flex flexWrap="wrap">
                <Heading
                  size="sm"
                  as="h5"
                  mr={1}
                  color={getColor(ctaLink?.textColor, ctaLink?.textColorRange)}
                >
                  {ctaLink?.text}
                </Heading>
                {ctaLink?.secondText && (
                  <Heading
                    size="sm"
                    as="h5"
                    mr={1}
                    color={getColor(
                      ctaLink?.secondTextColor,
                      ctaLink?.secondTextColorRange
                    )}
                  >
                    {ctaLink?.secondText}
                  </Heading>
                )}
                {ctaLink?.thirdText && (
                  <Heading
                    size="sm"
                    as="h5"
                    color={getColor(
                      ctaLink?.thirdTextColor,
                      ctaLink?.thirdTextColorRange
                    )}
                  >
                    {ctaLink?.thirdText}
                  </Heading>
                )}
              </Flex>
            )}
          </Link>
        )}
      </VStack>
    </VStack>
  );
};
